import * as React from 'react';

const RatingStarSVG = (props) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M7.3604 1.57639C7.55309 1.18857 8.10632 1.18857 8.29901 1.57639L9.87269 4.74368C9.94899 4.89726 10.0956 5.00378 10.2653 5.02889L13.7638 5.54681C14.1922 5.61022 14.3632 6.13638 14.0539 6.43948L11.5279 8.91488C11.4054 9.03491 11.3494 9.20727 11.3779 9.37637L11.9665 12.8638C12.0386 13.2908 11.591 13.6159 11.2071 13.4155L8.07232 11.778C7.92032 11.6986 7.73909 11.6986 7.58708 11.778L4.45226 13.4155C4.06842 13.6159 3.62085 13.2908 3.69291 12.8638L4.28147 9.37637C4.31 9.20727 4.254 9.03491 4.13152 8.91488L1.60554 6.43948C1.29625 6.13638 1.4672 5.61022 1.89558 5.54681L5.39415 5.02889C5.56379 5.00378 5.71041 4.89726 5.78672 4.74368L7.3604 1.57639Z' />
  </svg>
);

export default RatingStarSVG;
