import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useRef } from "react";
import Layout from "../components/layout";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import parse from "html-react-parser";
import { parseNestedHTML } from "../Utils";
import googleIcon from "../images/icons/google.svg";
import Marquee from "react-fast-marquee";
import NinjaStarSVG from "../components/svg/NinjaStarSVG";
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import Ratings from "react-star-ratings";
import RatingStarSVG from "../components/svg/RatingStarSVG";
import AddressAndTransport from "../components/AddressAndTransport";
import SafeHtmlParser from "../components/safe-html-parser";
import ContactForm from "../components/contactForm";
import ReviewSection from "../components/review-section";
import { v4 as uuidv4 } from "uuid";
import MailChimpForm from "../components/mail-chimp";
import WelcomeModal from "../components/welcome-modal";
const settings = {
	dots: true,
	dotsClass: "slick-dots slick-thumb",
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	autoplay: true,
};
// console.log(uuidv4());

const IndexPage = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			reviews {
				reviewCount
				reviews {
					author
					datePublished
					source
					reviewBody
				}
			}
			wpHomePage {
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				homepageFields {
					heroSection {
						btnText
						title
					}
					welcomeSection
					whyVisitNinjaArena {
						title
						btnText
						slider {
							image {
								node {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						reasons {
							reason
						}
					}
					sessions {
						session1 {
							btn1Text
							btn2Text
							subheading
							title
							image {
								node {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: FULL_WIDTH
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						session2 {
							btn1Text
							btn2Text
							image {
								node {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
							subheading
							title
						}
						session3 {
							title
							icon
							icon2
						}
						session4 {
							btn1Text
							btn2Text
							title
							subheading
							image {
								node {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						session5 {
							btn1Text
							btn2Text
							subheading
							title
							image {
								node {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: FULL_WIDTH
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
					reviews {
						btnText
						title
					}
					openingHours {
						subheading
						title
						time1 {
							title
							times {
								day
								time
							}
							image {
								node {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						time2 {
							image {
								node {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
							times {
								day
								time
							}
							title
						}
					}

					subscribe {
						btnText
						inputPlaceholder
						subheading
						title
					}
					address {
						btnText
						location {
							lat
							lng
						}
						title
					}
					vehiclesStatus {
						body
						title
						icon {
							node {
								altText
								sourceUrl
							}
						}
					}
					glimpse {
						btnText
						image1 {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image2 {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image3 {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image4 {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image5 {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					company
					description
					siteUrl
					title
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const HomePage = data.wpHomePage.homepageFields;
	const seoFields = data.wpHomePage.seoFields;

	// console.log();

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>

			<div>
				<WelcomeModal />
				<Layout>
					<section className="position-relative">
						<div className="position-relative ">
							<div
								style={{
									zIndex: 1,
									background:
										"linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%)",
									borderTop: "1px solid #FFF",
									borderBottom: "1px solid #FFF",
								}}
								className="position-absolute h-100 w-100 "
							>
								<Container className="h-100">
									<Row className="  h-100 align-items-center">
										<Col className=" align-left text-left pb-lg-5 py-4 pt-lg-0 text-white">
											<div className="stylishText text-uppercase  ">
												<SafeHtmlParser
													htmlContent={HomePage.heroSection.title}
												/>
											</div>
											<Button
												target="_blank"
												rel="noreferrer"
												href="https://ecom.roller.app/ninjaarenaeastbourne/checkout1/en/home"
												className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
											>
												{HomePage.heroSection.btnText}
											</Button>
										</Col>
									</Row>
								</Container>
							</div>
							<div
								className="d-md-none"
								style={{ maxHeight: "80vh", overflow: "hidden" }}
							>
								<ReactPlayer
									loop
									playing
									muted
									playsinline
									className="  hero-vid"
									url="https://ninja-arena-eastbourne.rjmdigital.net/wp-content/uploads/2024/04/Send-to-connor-Edited-Ninja-video-.mp4"
								/>
							</div>
							<div
								className="d-none d-md-block"
								style={{ maxHeight: "80vh", overflow: "hidden" }}
							>
								<ReactPlayer
									loop
									playing
									muted
									playsinline
									className="w-100 h-100 "
									url="https://ninja-arena-eastbourne.rjmdigital.net/wp-content/uploads/2024/04/Send-to-connor-Edited-Ninja-video-.mp4"
								/>
							</div>
						</div>
					</section>
					<section
						style={{
							borderBottom: "1px solid white",
							borderTop: "1px solid white",
						}}
						className="pt-2 d-none d-lg-block"
					>
						<Marquee>
							<div className="stylishText text-uppercase mx-2 display-2">
								<SafeHtmlParser htmlContent={HomePage.welcomeSection} />
							</div>
							<NinjaStarSVG />
							<div className="stylishText text-uppercase display-2">
								<SafeHtmlParser htmlContent={HomePage.welcomeSection} />
							</div>
							<NinjaStarSVG />
						</Marquee>
					</section>
					<section className="py-5 py-lg-7">
						<Container>
							<Row className="g-5 align-items-center">
								<Col lg={{ span: 6, order: "last" }}>
									<div className="slider-container">
										<Slider {...settings}>
											{HomePage.whyVisitNinjaArena.slider.map((slider, i) => (
												<div key={i}>
													<GatsbyImage
														image={
															slider.image?.node.localFile?.childImageSharp
																?.gatsbyImageData
														}
														alt={slider.image?.node?.altText}
													/>
												</div>
											))}
										</Slider>
									</div>
								</Col>
								<Col lg={6}>
									<h2 className="display-5 pb-4 text-uppercase">
										{HomePage.whyVisitNinjaArena.title}
									</h2>

									<div className="d-md-none">
										{HomePage.whyVisitNinjaArena.reasons.map(
											({ reason }, i) => (
												<Stack
													direction="horizontal"
													className="align-items-start"
													gap={1}
													key={i}
												>
													<NinjaStarSVG
														style={{ minWidth: "15px", maxWidth: "15px" }}
													/>
													<p className="text-white pt-1">{reason}</p>
												</Stack>
											)
										)}
									</div>
									<div className="d-none d-md-block">
										{HomePage.whyVisitNinjaArena.reasons.map(
											({ reason }, i) => (
												<Stack
													direction="horizontal"
													className="align-items-start"
													gap={2}
													key={i}
												>
													<NinjaStarSVG
														style={{ minWidth: "20px", maxWidth: "20px" }}
													/>
													<p className="text-white pt-1">{reason}</p>
												</Stack>
											)
										)}
									</div>
									<Button
										target="_blank"
										rel="noreferrer"
										href="https://ecom.roller.app/ninjaarenaeastbourne/checkout1/en/home"
										className="text-white text-uppercase mt-4 w-100 w-md-auto fs-5 px-4 py-2"
									>
										{HomePage.whyVisitNinjaArena.btnText}
									</Button>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="mt-5">
						<Row>
							<Col md={6} className="position-relative p-0">
								<div
									style={{
										position: "relative",
									}}
								>
									<div
										className="position-absolute top-0 start-0 end-0 bottom-0"
										style={{
											backgroundColor: "#FFE60033",
											zIndex: 1,
										}}
									/>
									<GatsbyImage
										image={
											HomePage.sessions.session1.image?.node.localFile
												?.childImageSharp?.gatsbyImageData
										}
										className="w-100 "
										style={{ objectFit: "cover" }}
										alt={HomePage.sessions.session1.image?.node?.altText}
									/>
									<div
										style={{ zIndex: 2 }}
										className="position-absolute buttons-in-square w-100 w-lg-auto buttons-in-square w-lg-auto px-5 px-md-4 px-lg-0 top-50 start-50 translate-middle"
									>
										<div className="stylishText  text-uppercase session-heading">
											<SafeHtmlParser
												htmlContent={HomePage.sessions.session1.title}
											/>
										</div>
										<p className="text-white ">
											{HomePage.sessions.session1.subheading}
										</p>

										<Button
											variant="white"
											as={Link}
											to="/adult-night-activities"
											className="text-primary text-uppercase me-3 mt-3 mt-lg-4 fs-5 px-xl-4 py-2"
										>
											{HomePage.sessions.session1.btn1Text}
										</Button>
										<Button
											variant="primary"
											target="_blank"
											rel="noreferrer"
											href="https://ecom.roller.app/ninjaarenaeastbourne/checkout1/en/home"
											className="text-white  text-uppercase mt-3 mt-lg-4 fs-5 px-xl-4 py-2"
										>
											{HomePage.sessions.session1.btn2Text}
										</Button>
									</div>
								</div>
							</Col>
							<Col md={6} className="position-relative p-0">
								<div
									style={{
										position: "relative",
									}}
								>
									<div
										className="position-absolute top-0 start-0 end-0 bottom-0"
										style={{
											backgroundColor: "#CC00FF33",
											zIndex: 1,
										}}
									/>
									<GatsbyImage
										image={
											HomePage.sessions.session2.image?.node.localFile
												?.childImageSharp?.gatsbyImageData
										}
										className="w-100 "
										style={{ objectFit: "cover" }}
										alt={HomePage.sessions.session2.image?.node?.altText}
									/>
									<div
										style={{ zIndex: 2 }}
										className="position-absolute buttons-in-square w-100 w-lg-auto px-5 px-md-4 top-50 start-50 translate-middle"
									>
										<div className="stylishText text-uppercase session-heading">
											<SafeHtmlParser
												htmlContent={HomePage.sessions.session2.title}
											/>
										</div>
										<p className="text-white">
											{HomePage.sessions.session2.subheading}
										</p>

										<Button
											variant="white"
											as={Link}
											to="/team-building-activities-eastbourne"
											className="text-primary text-uppercase mt-3 me-3 mt-lg-4 fs-5 px-xl-4 py-2"
										>
											{HomePage.sessions.session2.btn1Text}
										</Button>
										<Button
											variant="primary"
											as={Link}
											to="/team-building-activities-eastbourne#form"
											className="text-white  text-uppercase mt-3 mt-lg-4 fs-5 px-xl-4 py-2"
										>
											{HomePage.sessions.session2.btn2Text}
										</Button>
									</div>
								</div>
							</Col>
						</Row>
						<div
							className="py-3"
							style={{
								backgroundColor: "#BF002B",
							}}
						>
							<Container>
								<Row>
									<Col
										lg={6}
										className="position-relative text-center p-0 session3"
									>
										<Stack
											direction="horizontal"
											gap={3}
											className="align-items-center d-none d-md-flex justify-content-center justify-content-md-start "
										>
											<div className="d-flex align-items-center">
												<a
													target="_blank"
													rel="noreferrer"
													className="white-link-black  d-inline-block me-3  fs-2 social-icon"
													href="https://www.instagram.com/ninjaeastbourne/"
												>
													<AiFillInstagram />
												</a>
												<a
													target="_blank"
													rel="noreferrer"
													className="white-link-black me-xl-4 d-inline-block   fs-2 social-icon"
													href="https://www.facebook.com/NinjaArenaEastbourne"
												>
													<FaFacebookSquare />
												</a>
											</div>
											<div className="title stylishText">
												<SafeHtmlParser
													htmlContent={HomePage.sessions.session3.title}
												/>
											</div>
										</Stack>
										<Stack
											direction="horizontal"
											gap={3}
											className="align-items-start d-md-none justify-content-center justify-content-md-start "
										>
											<div className="title stylishText position-relative">
												<SafeHtmlParser
													htmlContent={HomePage.sessions.session3.title}
												/>
												<div className="d-flex me-3 position-absolute top-0 end-0 align-items-center">
													<a
														target="_blank"
														rel="noreferrer"
														className="white-link-black  d-inline-block me-3  fs-2 social-icon"
														href="https://www.instagram.com/ninjaeastbourne/"
													>
														<AiFillInstagram />
													</a>
													<a
														target="_blank"
														rel="noreferrer"
														className="white-link-black me-xl-4 d-inline-block   fs-2 social-icon"
														href="https://www.facebook.com/NinjaArenaEastbourne"
													>
														<FaFacebookSquare />
													</a>
												</div>
											</div>
										</Stack>
									</Col>
								</Row>
							</Container>
						</div>
						<Row>
							<Col md={6} className="position-relative p-0">
								<div
									style={{
										position: "relative",
									}}
								>
									<div
										className="position-absolute top-0 start-0 end-0 bottom-0"
										style={{
											backgroundColor: "#FFE60033",
											zIndex: 1,
										}}
									/>
									<GatsbyImage
										image={
											HomePage.sessions.session4.image?.node.localFile
												?.childImageSharp?.gatsbyImageData
										}
										className="w-100 "
										style={{ objectFit: "cover" }}
										alt={HomePage.sessions.session4.image?.node?.altText}
									/>
									<div
										style={{ zIndex: 2 }}
										className="position-absolute buttons-in-square w-100 w-lg-auto px-5 px-md-4 top-50 start-50 translate-middle"
									>
										<div className="stylishText text-uppercase session-heading">
											<SafeHtmlParser
												htmlContent={HomePage.sessions.session4.title}
											/>
										</div>
										<p className="text-white">
											{HomePage.sessions.session4.subheading}
										</p>

										<Button
											variant="white"
											as={Link}
											to="/ninja-birthday-parties"
											className="text-primary text-uppercase me-3 mt-3 mt-lg-4 fs-5 px-xl-4 py-2"
										>
											{HomePage.sessions.session4.btn1Text}
										</Button>
										<Button
											variant="primary"
											as={Link}
											to="/ninja-birthday-parties#form"
											className="text-white  text-uppercase mt-3 mt-lg-4 fs-5 px-xl-4 py-2"
										>
											{HomePage.sessions.session4.btn2Text}
										</Button>
									</div>
								</div>
							</Col>
							<Col md={6} className="position-relative p-0">
								<div
									style={{
										position: "relative",
									}}
								>
									<div
										className="position-absolute top-0 start-0 end-0 bottom-0"
										style={{
											backgroundColor: "#FF004D33",
											zIndex: 1,
										}}
									/>
									<GatsbyImage
										image={
											HomePage.sessions.session5.image?.node.localFile
												?.childImageSharp?.gatsbyImageData
										}
										className="w-100 "
										style={{ objectFit: "cover" }}
										alt={HomePage.sessions.session5.image?.node?.altText}
									/>
									<div
										style={{ zIndex: 2 }}
										className="position-absolute buttons-in-square w-100 w-lg-auto px-5 px-md-4 top-50 start-50 translate-middle"
									>
										<div className="stylishText text-uppercase session-heading">
											<SafeHtmlParser
												htmlContent={HomePage.sessions.session5.title}
											/>
										</div>
										<p className="text-white">
											{HomePage.sessions.session5.subheading}
										</p>

										<Button
											variant="white"
											as={Link}
											to="/mini-ninjas"
											className="text-primary text-uppercase me-3 mt-3 mt-lg-4 fs-5 px-xl-4 py-2"
										>
											{HomePage.sessions.session5.btn1Text}
										</Button>
										<Button
											variant="primary"
											target="_blank"
											rel="noreferrer"
											href="https://ecom.roller.app/ninjaarenaeastbourne/checkout1/en/home"
											className="text-white  text-uppercase mt-3 mt-lg-4 fs-5 px-xl-4 py-2"
										>
											{HomePage.sessions.session5.btn2Text}
										</Button>
									</div>
								</div>
							</Col>
						</Row>
					</section>
					<section className="pt-5 pt-lg-7">
						<Container>
							<h2 className="display-5 pb-4 text-center text-uppercase">
								{HomePage.reviews.title}
							</h2>

							<ReviewSection fromIndex={0} toIndex={6} black />
							<div className="flex d-flex justify-content-center m-3">
								<Button
									target="_blank"
									rel="noreferrer"
									href="https://www.google.com/maps/place/Ninja+Arena,+Eastbourne/@50.769601,0.28568,14z/data=!4m8!3m7!1s0x47df7341975be679:0x63c07fe02c3335f3!8m2!3d50.7696006!4d0.2856804!9m1!1b1!16s%2Fg%2F11t5y526t4?hl=en&entry=ttu"
									className="text-white w-100 w-md-auto text-uppercase mt-4 fs-5 px-4 py-2"
								>
									{HomePage.reviews.btnText}
								</Button>
							</div>
						</Container>
					</section>
					<section className="pt-5 py-lg-7">
						<h2 className="display-5  text-uppercase text-white text-center">
							{HomePage.openingHours.title}
						</h2>

						<p className="text-center text-white fs-4 pb-4">
							{HomePage.openingHours.subheading}
						</p>
						<Row className="align-items-center gx-0">
							<Col lg={6}>
								<div
									style={{
										position: "relative",
										display: "inline-block", // Or 'block' depending on your layout
									}}
								>
									<div
										style={{
											position: "absolute",
											top: 0,
											right: 0,
											bottom: 0,
											left: 0,
											background:
												"linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)",
											pointerEvents: "none", // Allows interaction with the GatsbyImage
											zIndex: 1, // Ensures the overlay is above the image
										}}
									></div>
									<GatsbyImage
										image={
											HomePage.openingHours.time1.image?.node.localFile
												?.childImageSharp?.gatsbyImageData
										}
										className="w-100"
										style={{
											objectFit: "cover",
											width: "100%",
										}}
										alt={HomePage.openingHours.time1.image?.node?.altText}
									/>
								</div>
							</Col>
							<Col className="px-lg-8 p-4 py-lg-0" lg={6}>
								<h3 className="display-5  text-uppercase text-white ">
									{HomePage.openingHours.time1.title}
								</h3>
								{HomePage.openingHours.time1.times.map(({ day, time }, i) => (
									<p className="" key={i}>
										<span className="karla-bold text-white">{day}: </span>
										{time}
									</p>
								))}
							</Col>
						</Row>
						<Row className="align-items-center gx-0">
							<Col lg={{ span: 6, order: "last" }}>
								<div
									style={{
										position: "relative",
										display: "inline-block", // Or 'block' depending on your layout
									}}
								>
									<div
										style={{
											position: "absolute",
											top: 0,
											right: 0,
											bottom: 0,
											left: 0,
											background:
												"linear-gradient(to left, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)",
											pointerEvents: "none", // Allows interaction with the GatsbyImage
											zIndex: 1, // Ensures the overlay is above the image
										}}
									></div>
									<GatsbyImage
										image={
											HomePage.openingHours.time2.image?.node.localFile
												?.childImageSharp?.gatsbyImageData
										}
										className="w-100"
										style={{
											objectFit: "cover",
										}}
										alt={HomePage.openingHours.time2.image?.node?.altText}
									/>
								</div>
							</Col>
							<Col className="px-lg-8 p-4 py-lg-0" lg={6}>
								<h3 className="display-5  text-uppercase text-white ">
									{HomePage.openingHours.time2.title}
								</h3>
								{HomePage.openingHours.time2.times.map(({ day, time }, i) => (
									<p className="" key={i}>
										<span className="karla-bold text-white">{day}: </span>
										{time}
									</p>
								))}
							</Col>
						</Row>
					</section>
					<section className="bg-primary py-5 ">
						<Container>
							<Row className=" g-4 align-items-center">
								<Col className="text-lg-center" xl={12}>
									<h2>{HomePage.subscribe.title}</h2>
									<h4>{HomePage.subscribe.subheading}</h4>
								</Col>
								<Col className="text-lg-end pt-xl-3" xl={12}>
									<MailChimpForm />
									<p className="mt-2 text-lg-center text-white">
										Opt out at any time. Click{" "}
										<Link
											to="/policies/privacy-policy"
											className="white-link-black text-decoration-underline"
										>
											here
										</Link>{" "}
										to view our privacy policy.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 pt-lg-7">
						<AddressAndTransport
							address={HomePage.address}
							vehiclesStatus={HomePage.vehiclesStatus}
						/>
					</section>
					<section>
						<Container>
							<Row className="g-3">
								<Col lg={6}>
									<div
										className="h-100 w-100"
										style={{ borderRadius: "16px", overflow: "hidden" }}
									>
										<GatsbyImage
											image={
												HomePage.glimpse.image1?.node.localFile?.childImageSharp
													?.gatsbyImageData
											}
											className="w-100 h-100"
											alt={HomePage.glimpse.image1?.node?.altText}
										/>
									</div>
								</Col>
								<Col lg={6}>
									<Row className="g-3">
										<Col xs={6}>
											<div
												className="h-100"
												style={{ borderRadius: "16px", overflow: "hidden" }}
											>
												<GatsbyImage
													image={
														HomePage.glimpse.image2?.node.localFile
															?.childImageSharp?.gatsbyImageData
													}
													className="w-100 h-100"
													alt={HomePage.glimpse.image2?.node?.altText}
												/>
											</div>
										</Col>
										<Col xs={6}>
											<div style={{ borderRadius: "16px", overflow: "hidden" }}>
												<GatsbyImage
													image={
														HomePage.glimpse.image3?.node.localFile
															?.childImageSharp?.gatsbyImageData
													}
													className="w-100 h-100"
													alt={HomePage.glimpse.image3?.node?.altText}
												/>
											</div>
										</Col>
										<Col xs={6}>
											<div style={{ borderRadius: "16px", overflow: "hidden" }}>
												<GatsbyImage
													image={
														HomePage.glimpse.image4?.node.localFile
															?.childImageSharp?.gatsbyImageData
													}
													className="w-100 h-100"
													alt={HomePage.glimpse.image4?.node?.altText}
												/>
											</div>
										</Col>
										<Col xs={6}>
											<div style={{ borderRadius: "16px", overflow: "hidden" }}>
												<GatsbyImage
													image={
														HomePage.glimpse.image5?.node.localFile
															?.childImageSharp?.gatsbyImageData
													}
													className="w-100 h-100"
													alt={HomePage.glimpse.image5?.node?.altText}
												/>
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col className="text-center">
									<Button
										target="_blank"
										rel="noreferrer"
										href="https://ecom.roller.app/ninjaarenaeastbourne/checkout1/en/home"
										className="text-white w-100 w-md-auto text-uppercase mt-5 fs-5 px-4 py-2"
									>
										{HomePage.glimpse.btnText}
									</Button>
								</Col>
							</Row>
						</Container>
					</section>
					<section id="form" className="py-5 py-lg-7">
						<Container>
							<Row>
								<Col>
									<h2 className="display-5 pb-3 text-uppercase text-center">
										Get in Touch
									</h2>

									<ContactForm />
								</Col>
							</Row>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default IndexPage;
